import React, { useContext, useEffect, useState } from "react";
import { Switch, Typography, Tooltip, Box, Select, Option, Button } from "@mui/joy";
import { axiosInstance } from "../../helpers/axiosConfig.js";
import { getUserID } from "../../helpers/AuthBarrier";
import { AlertContext } from "../../components/AlertFlag.js";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { convertTimeString } from "../../helpers/TimeConversionHelper";
import NavBlocker from "../../components/NavBlocker.js";

function SettingsView() {
    const { addAlert } = useContext(AlertContext);
    const [ splitHPI, setSplitHPI ] = useState(true);
    const [ noteFormat, setNoteFormat] = useState('');
    const [ noteType, setNoteType ] = useState('');
    const [ updatedAt, setUpdatedAt] = useState('');
    const [ voiceLanguage, setVoiceLanguage ] = useState('en-US');
    const [ noteLanguage, setNoteLanguage ] = useState('en-US');

    const [initialState, setInitialState ] = useState({
        splitHPI: false,
        noteFormat: '',
        noteType: '',
        voiceLanguage: '',
        noteLanguage: '',
    });
    const [ dirty, setDirty] = useState(false);

    const handleNoteType = (event, newValue) => {
        setNoteType(newValue);
    }

    const handleVoiceLanguage = (event, voiceInput) => {
        setVoiceLanguage(voiceInput);
    }

    const handleNoteLanguage = (event, noteLanguage) => {
        setNoteLanguage(noteLanguage);
    }

    const handleSplitHPI = (event) => {
        setSplitHPI(event.target.checked);
    }

    const handleNoteFormat = (event, newValue) => {
        setNoteFormat(newValue);
    }

    const getUserPreferences = async () => {
        let response;
        await axiosInstance.get(`/api/v1/user/${getUserID()}/preference`).then( retrieved => {
            response = retrieved.data;
            setSplitHPI(response['split_hpi']);
            setNoteFormat(response['note_format']);
            setNoteType(response['note_type']);
            setVoiceLanguage(response['input_language']);
            setNoteLanguage(response['output_language']);
            setUpdatedAt(convertTimeString(response['updated_at']));

            setInitialState({
                splitHPI: response['split_hpi'],
                noteFormat: response['note_format'],
                noteType: response['note_type'],
                voiceLanguage: response['input_language'],
                noteLanguage: response['output_language'],
            })
        }).catch(error => {
            addAlert("There was a problem retrieving your settings", "danger")
        })
    }

    useEffect(() => {
        getUserPreferences();
    }, [])

    useEffect(() => {
        if (splitHPI !== initialState['splitHPI'] || noteFormat !== initialState['noteFormat'] || noteType !== initialState['noteType'] ||  voiceLanguage !== initialState['voiceLanguage'] || noteLanguage !== initialState['noteLanguage']) { 
            setDirty(true);
        } else {
            setDirty(false);
        }
    }, [noteFormat, splitHPI, noteType, initialState]);

    const handleSavePreferences = async () => {
        await axiosInstance.put(`/api/v1/user/${getUserID()}/preference`, {
            "split_hpi": splitHPI,
            "note_format": noteFormat,
            "note_type": noteType,
            "input_language": voiceLanguage,
            "output_language": noteLanguage,
        }).then( response => {
            addAlert("Settings saved!", 'success');

            setInitialState({
                splitHPI,
                noteFormat,
                noteType,
                voiceLanguage,
                noteLanguage,
            })
        }).catch(error => {
            addAlert("There was a problem saving your settings", 'danger');
        })
    }
    

    return (
        <Box id="outline-it" sx={(theme) => ({
            display: "flex", flexDirection: "column", boxShadow: theme.shadow.md, gap: 2,
        })}>
            <NavBlocker dirty={dirty} />
            <Typography level='h2'>Settings</Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: {xs: 'column', lg: 'row'},
                    justifyContent: 'space-between',
                    gap: 2,
                    px: "1rem"
                }}
            >
                <Box
                    sx={{display: 'flex', alignItems: 'center', gap: "1rem", justifyContent: 'space-between'}}
                >
                    <Typography>Note Type:</Typography>
                    <Box sx={{display: 'flex',  alignItems: 'center', gap: "1rem"}}>
                        <Select
                            value={noteType}
                            onChange={handleNoteType}
                            sx={{
                                width: {
                                    xs: "10rem", 
                                    sm: "10vw", 
                                    md: "20rem",  
                                    lg: "10vw"
                                },
                            }}
                        >
                            <Option value="adhd_letter"> ADHD Letter </Option>
                            <Option value="athletic">Athletic Note</Option>
                            <Option value="athletic_assessment"> Athletic Assessment Form</Option>
                            <Option value="athlete_daily_treatment"> Athlete Daily Treatment note </Option>
                            <Option value="autism_diagnostic_letter"> Autism Diagnostic Letter </Option>
                            <Option value="chiropractic">Chiropractic Note</Option>
                            <Option value="consult_note">Consult Note</Option>
                            <Option value="consult_note_condensed"> Consult Note Condensed</Option>
                            <Option value="developmental_consult"> Developmental Consultation</Option>
                            <Option value="developmental_followup"> Developmental Follow-up</Option>
                            <Option value="emergency_response"> Emergency Response</Option>
                            <Option value="medical_assessment"> Medical Assessment </Option>
                            <Option value="nine_month_development_assessment"> Nine Month Development Assessment </Option>
                            <Option value="pace_rehab_treatment_note"> PACE - Rehab Treatment Note </Option>
                            <Option value="patient_handout">Patient Handout</Option>
                            <Option value="patient_summary">Patient Summary</Option>
                            <Option value="physio">Physiotherapy Note</Option>
                            <Option value="progress_soap_note"> Progress SOAP Note</Option>
                            <Option value="radiology"> Radiology</Option>
                            <Option value="referral_letter">Referral Letter</Option>
                            <Option value="referrel_letter_short"> Referral Letter Short </Option>
                            <Option value="sports_medicine_consult_letter">Sports Medical Consult Letter</Option>
                            <Option value="sports_medicine">Sports Medical Note</Option>
                            <Option value="therapy_note"> Therapy Note</Option>
                            <Option value="therapy_case_note"> Therapy - Case Note</Option>
                            <Option value="therapy_cbt_dbt"> Therapy - CBT / DBT Note</Option>
                            <Option value="therapy_family_remediation"> Therapy - Family Mediation Note</Option>
                            <Option value="therapy_intake_assessment_adult"> Therapy - Intake Assessment Adult</Option>
                            <Option value="therapy_intake_assessment_child"> Therapy - Intake Assessment Child</Option>
                            <Option value="therapy_parenting_plan"> Therapy - Parenting Plan Note</Option>
                            <Option value="therapy_marriage_counselling"> Therapy - Partner Counselling Note</Option>
                        </Select>
                        <Tooltip title={'Note Type specifies what information is contained in the structured note.'}> 
                            <HelpOutlineIcon/> 
                        </Tooltip>
                    </Box>
                </Box>
                <Box
                    sx={{display: 'flex', alignItems: 'center', gap: "1rem",  justifyContent: 'space-between'}}
                >
                    <Typography>Note Format:</Typography>
                    <Box sx={{display: 'flex',  alignItems: 'center', gap: "1rem"}}>
                        <Select
                            value={noteFormat}
                            onChange={handleNoteFormat}
                            sx={{
                                width: {
                                    xs: "10rem", 
                                    sm: "10vw", 
                                    md: "20rem", 
                                    lg: "10vw"
                                },
                            }}
                        >
                            <Option value="default">Default</Option>
                            <Option value="paragraph">Paragraph</Option>
                            <Option value="bullet_points">Bullet Points</Option>
                        </Select>
                        <Tooltip title={'Note Format determines the formatting of the structured note.'}> 
                            <HelpOutlineIcon/> 
                        </Tooltip>
                    </Box>
                </Box>
                <Box
                    sx={{alignItems: 'center', gap: "1rem",  justifyContent: 'space-between'}}
                >
                    <Box sx={{display: 'flex',  alignItems: 'center', gap: "1rem", pb: '1rem'}}>
                        <Typography>Voice Language:</Typography>
                        <Select value={voiceLanguage} onChange={handleVoiceLanguage} sx={{width: '10rem'}}>
                            <Option value="nl">Dutch</Option>
                            <Option value="en">English</Option>
                            <Option value="en-US">English(USA)</Option>
                            <Option value="fr">French</Option>
                            <Option value="fr-CA">French(Québecois)</Option>
                            <Option value="de">German</Option>
                            <Option value="ja">Japanese</Option>
                            <Option value="ko">Korean</Option>
                            <Option value="pl">Polish</Option>
                            <Option value="ru">Russian</Option>
                            <Option value="es">Spanish</Option>
                        </Select>
                    </Box>
                    <Box sx={{display: 'flex',  alignItems: 'center', gap: "1rem"}}>
                        <Typography pr={'5px'}>Note Language: </Typography>
                        <Select value={noteLanguage} onChange={handleNoteLanguage} sx={{width: '10rem'}}>
                            <Option value="nl">Dutch</Option>
                            <Option value="en">English</Option>
                            <Option value="en-US">English(USA)</Option>
                            <Option value="fr">French</Option>
                            <Option value="fr-CA">French(Québecois)</Option>
                            <Option value="de">German</Option>
                            <Option value="ja">Japanese</Option>
                            <Option value="ko">Korean</Option>
                            <Option value="pl">Polish</Option>
                            <Option value="ru">Russian</Option>
                            <Option value="es">Spanish</Option>
                        </Select>
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex', justifyContent: 'center', alignItems: 'center',
                }}>
                    <Typography>
                        Last updated at: {updatedAt}
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    alignItems: 'start',
                    gap: 2,
                    px: "1rem"
                }}
            >
                
                <Box sx={{ display: 'flex', width: '100%', justifyContent: { xs: 'center', md: 'start' }, }}>
                    <Button onClick={handleSavePreferences} size='lg' sx={{width: '10rem',}}>Save</Button>
                </Box>
            </Box>
        </Box>
    )
}

export default SettingsView;